<template>
    <div>
        <h2>{{ $t("payment.add_payments") }}</h2>
        <div class="box">
            <div class="col-12">
                <textarea class="form-control" rows="10" v-model="csv"></textarea>
            </div>

            <div class="col-12 text-center mt-3">
                <b-button class="col-3" variant="primary" @click.prevent="search"><font-awesome-icon v-if="load_csv" :icon="['fas', 'spinner']" pulse /> {{ $t("global.rechercher") }}</b-button>
            </div>

            <div v-if="payments">
                <hr class="my-5">

                <table class="table">
                    <tr>
                        <th>{{ $t("global.date") }}</th>
                        <th>{{ $t("global.information") }}</th>
                        <th>{{ $t("payment.payment_amount") }}</th>
                        <th colspan="2">{{ $t("payment.liste_factures_possibles") }}</th>
                        <th>{{ $t("compta.form.numero_facture") }}</th>
                    </tr>
                    <tr v-for="payment in payments" :key="payment.id">
                        <td>{{ payment.date_format }}</td>
                        <td>{{ payment.detail }}</td>
                        <td>{{ payment.montant_format }}</td>
                        <td>
                            <font-awesome-icon class="text-success" v-if="payment.match" :icon="['fal', 'check-circle']" />
                            <font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
                        </td>
                        <td>
                            <table class="table" v-if="payment.matchs">
                                <tr>
                                    <th>{{ $t("compta.form.numero_facture") }}</th>
                                    <th>{{ $t("invoice.invoice_balance") }}</th>
                                    <th>{{ $t("tiers.tier") }}</th>
                                    <th>{{ $t("payment.duedate_date") }}</th>
                                    <th>{{ $t("payment.duedate_balance") }}</th>
                                </tr>
                                <tr v-for="invoice in payment.matchs" :key="invoice.invoice_id">
                                    <td><a href="#" @click.prevent="link(payment.id, invoice.invoice_num)">{{ invoice.invoice_num }}</a></td>
                                    <td>{{ invoice.invoice_balance_format }}</td>
                                    <td>{{ invoice.tiers_rs }}</td>
                                    <td>{{ invoice.duedate_date_format }}</td>
                                    <td>{{ invoice.duedate_balance_format }}</td>
                                </tr>
                            </table>
                        </td>
                        <td>
                            <e-select
                                track-by="label"
                                label="label"
                                v-model="tiers_selected[payment.id]"
                                :options="tiers"
                                :show-labels="false"
                                :loading="isLoading"
                            >
                            </e-select>
                        </td>
                    </tr>
                </table>
    
                <div class="col-12 text-center mt-3">
                    <b-button class="col-3" variant="primary" @click.prevent="add"><font-awesome-icon v-if="load_add" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import Vue from 'vue'
    import { EventBus } from 'EventBus'
    import Payment from "@/mixins/Payment.js"
    import Csv from "@/mixins/Csv.js"
    import _orderBy from 'lodash/orderBy'

    export default {
        name: "addPaymentFromBank",
        mixins: [Payment, Csv],
        props: [],
        data () {
            return {
                csv: null,
                load_csv: false,
                payments: null,
                tiers: [],
                tiers_selected: [],
                load_add: false,
                isLoading: false
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.csv = null
                this.payments = null
                this.tiers = []
                this.tiers_selected = []
            },
            async search() {
                if(this.load_csv) {
                    return;
                }

                this.payments = null
                this.tiers = []
                this.tiers_selected = []
                this.load_csv = true
                const payments = await this.loadPaymentsFromBank(this.csv)
                if(payments) {
                    const tiers = payments.tiers
                    for(const k_tiers in tiers) {
                        for(const k_invoice in tiers[k_tiers].invoices) {
                            this.tiers.push({
                                tiers_id: tiers[k_tiers].tiers_id,
                                tiers_rs: tiers[k_tiers].tiers_rs,
                                invoice_id: tiers[k_tiers].invoices[k_invoice].invoice_id,
                                invoice_num: tiers[k_tiers].invoices[k_invoice].invoice_num,
                                label: tiers[k_tiers].tiers_rs+' - '+tiers[k_tiers].invoices[k_invoice].invoice_num
                            })
                        }
                    }

                    const csv = payments.csv
                    for(const key in csv) {
                        if(csv[key].match == true && Object.keys(csv[key].matchs).length == 1) {
                            const first_key = Object.keys(csv[key].matchs)[0]
                            const invoice = csv[key].matchs[first_key]
                            if(invoice) {
                                this.tiers_selected[csv[key].id] = {
                                    tiers_id: invoice.tiers_id,
                                    tiers_rs: invoice.tiers_rs,
                                    invoice_id: invoice.invoice_id,
                                    invoice_num: invoice.invoice_num,
                                    label: invoice.tiers_rs+' - '+invoice.invoice_num
                                }
                            }
                        }
                    }

                    this.payments = csv
                }

                this.load_csv = false
            },
            async add() {
                if(this.load_add) {
                    return
                }

                this.load_add = true
                let data = []
                let key_selected = []
                for(const key in this.tiers_selected) {
                    if(this.tiers_selected[key]) {
                        const result = this.payments.filter(function(v, i) {
                            return v.id == key
                        })

                        if(result && result.length > 0) {
                            const temp = this.tiers_selected[key]
                            temp.detail = result[0].detail
                            temp.date = result[0].date
                            temp.montant = result[0].montant
                            data.push(temp)
                            key_selected.push(parseInt(key))
                        }
                    }
                }

                let error = []
                for(const key in this.payments) {
                    if(!key_selected.includes(this.payments[key].id)) {
                        error.push([this.payments[key].date_format, this.payments[key].detail, this.payments[key].montant_format.replace(',', '.')])
                    }
                }

                if(data.length > 0) {
                    const result = await this.savePaymentsFromBank(data)
                    if(result) {
                	    this.successToast()
                        if(error.length > 0) {
                            this.downloadCsv(error, 'export.csv', '\t')
                        }

                        this.init_component()
                    } else {
                	    this.failureToast()
                    }
                }

                this.load_add = false
            },
            link(payment_id, invoice_num) {
                this.isLoading = true
                const result = this.tiers.filter(tier => tier.invoice_num == invoice_num)
                if(result.length == 1) {
                    this.tiers_selected[payment_id] = result[0]
                }

                this.isLoading = false
            }
        },
    }
</script>
